<template>
  <el-card class="box-card">
    <el-form style="margin-top: 30px" ref="form" label-width="130px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="活动名称" prop="">
        <el-tooltip placement="top">
          <div slot="content">活动名称将显示在活动列表中，方便商家管理使用</div>
          <el-input v-model="form.name" style="width: 25%" size="small" placeholder="请输入活动名称"/>
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动时间" prop="">
        <el-date-picker
            v-model="query_time"
            size="small"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            align="right"
            @change="getTimeSection"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="参与人数" prop="">
        <el-tooltip placement="top">
          <div slot="content">最少两人成团</div>
          <el-input-number v-model="form.name" controls-position="right" step-strictly :min="2" size="small"/>
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="拼团有效期" prop="">
        <el-date-picker
            v-model="form.time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="限制购买" prop="">
        <el-tooltip placement="top">
          <div slot="content">本次活动中一次最多可购买的商品数量，默认为1</div>
          <el-input-number v-model="form.name" controls-position="right" :min="1" step-strictly size="small"/>
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="团长优惠" prop="">
        <el-tooltip placement="top">
          <div slot="content">开启团长（开团人）优惠后，团长将享受更优惠价格，有助于提高开团和成团率</div>
          <el-switch
              v-model="form.checked"
              active-color="#13ce66"
              active-text="是"
              active-value="1"
              inactive-color="#ff4949"
              inactive-text="否"
              inactive-value="0"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动商品" prop="">
        <el-table v-loading="listLoading" :data="s_list" size="small" style="width: 70%"
                  @selection-change="setSelectRows">
          <el-table-column align="center" show-overflow-tooltip type="selection"/>
          <el-table-column
              align="center"
              label="商品图片"
              prop="imgUrl"
          >
            <template #default="{row}">
              <el-image v-if="row.imgUrl" :src="row.imgUrl" class="commodity_pic"/>
              <span v-else>暂无图片</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="商品名称"
              prop="name"
          />
          <el-table-column
              align="center"
              label="商品价格"
              prop="good_price"
          />
          <el-table-column
              align="center"
              label="库存"
              prop="stock"
          />
          <el-table-column
              align="center"
              label="拼团价格(元)"
              prop="price"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.price" v-model="s_list[scope.$index].price"
                        placeholder="请输入内容" :value="scope.row.price"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].price" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column
              v-if="form.checked == 1"
              align="center"
              label="团长优惠价(元)"
              prop="quantity"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.quantity" v-model="s_list[scope.$index].quantity"
                        placeholder="请输入内容" :value="scope.row.quantity"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].quantity" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="100">
            <template #default="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button icon="el-icon-plus" type="primary" @click="showPop = true" size="small" style="margin-top: 10px">
          选择商品
        </el-button>
        <el-button v-if="s_list.length > 0" icon="el-icon-delete" size="small" type="danger" style="margin-top: 10px"
                   @click="handleDelete">批量删除
        </el-button>
        <el-button v-else icon="el-icon-delete" type="danger" size="small" style="margin-top: 10px" disabled>批量删除
        </el-button>
        <PopView :visible.sync="showPop" :chooseGoods="chooseGoods" title="选择商品" @confirm="showPopSubmit"></PopView>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="单独购买" prop="">
        <el-switch
            v-model="form.namea"
            active-color="#13ce66"
            active-text="是"
            active-value="1"
            inactive-color="#ff4949"
            inactive-text="否"
            inactive-value="0"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="虚拟成团" prop="">
        <el-switch
            v-model="form.namea"
            active-color="#13ce66"
            active-text="是"
            active-value="1"
            inactive-color="#ff4949"
            inactive-text="否"
            inactive-value="0"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="备注" prop="">
        <el-tooltip placement="top">
          <div slot="content">备注是商家对限时折扣的补充说明文字，<br/>在商品详情页-优惠信息位置显示；非必填选项</div>
          <el-input
              type="textarea"
              :autosize="{ minRows: 2}"
              v-model="form.name"
              style="width: 25%"
              size="small"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" size="small">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>

import PopView from "@/views/components/popView";

export default {
  components: {PopView},
  props: {
    chooseGoods: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [],
      s_list: [],
      showPop: false,
      query_time: [],
      listLoading: false,
      selectRows: '',
      form: {
        name: '',
        user: [],
        checked: 0
      },
      rules: {},
    }
  },
  created() {
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    //选择商品
    showPopSubmit(e) {
      this.showPop = true;
      console.log(e)
      if (e) {
        e.forEach(item => {
          this.s_list.push({
            sku_id: item.skuid,
            imgUrl: item.gallery,//商品图片
            name: item.name,//商品名
            good_price: item.price,//价格
            stock: item.stock,//库存
            price: item.price,//团购价
            quantity: 1,//最低团购数量
          })
        })
        const smap = new Map()
        // 对id属性进行去重
        this.s_list = this.s_list.filter(key => !smap.has(key.sku_id) && smap.set(key.sku_id, 1))
        this.listLoading = false
      }
    },
    //删除选中商品
    handleDelete(row) {
      if (row.sku_id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.s_list.map((val, i) => {
            if (val.sku_id == row.sku_id) {
              this.s_list.splice(i, 1)
            }
          })
        })
      } else {
        this.$confirm('你确定要删除所有选中项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          for (var j = 0; j < this.selectRows.length; j++) {
            this.s_list.map((val, i) => {
              if (val.sku_id == this.selectRows[j].sku_id) {
                this.s_list.splice(i, 1)
              }
            })
          }
        })
      }
    },
    //获取时间段
    getTimeSection(event) {
      console.log(event)
      if (event != null) {
        this.queryForm.start_at = event[0]
        this.queryForm.end_at = event[1]
      } else {
        this.queryForm.start_at = ''
        this.queryForm.end_at = ''
      }
    },
    async submit() {
      const {code, message} = await Cac(this.form)
      if (code == '200') {
        this.$message.success(message)
        this.$emit('fetch-data')
        this.close()
      } else {
        this.$message.error(message)
        this.$emit('fetch-data')
        this.close()
      }
    },
  }
}

</script>
<style>
.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.el-form-item {
  margin-bottom: 0;
}

.el-dropdown {
  margin-right: 10px;
}

.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
}

.el-row {
  margin-bottom: 15px;
}
</style>
